import { useState, useCallback, useLayoutEffect } from "react";

export function useBoundingRect(limit?: number) {
  const [node, setNode] = useState<null | HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState<null | ReturnType<
    typeof getDimensionObject
  >>(null);

  const ref = useCallback((node: null | HTMLDivElement) => {
    setNode(node);
  }, []);

  useLayoutEffect(() => {
    if ("undefined" !== typeof window && node) {
      const measure = () =>
        window.requestAnimationFrame(() =>
          setDimensions(getDimensionObject(node))
        );

      measure();

      const listener = debounce(limit ? limit : 100, measure);

      window.addEventListener("resize", listener);
      window.addEventListener("scroll", listener);
      return () => {
        window.removeEventListener("resize", listener);
        window.removeEventListener("scroll", listener);
      };
    }
  }, [node, limit]);

  return [ref, dimensions, node] as const;
}

function debounce(limit: number, callback: (timeoutId: number) => void) {
  let timeoutId: number;
  return (...args: unknown[]) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(callback, limit, args);
  };
}
function getDimensionObject(node: HTMLDivElement) {
  const rect = node.getBoundingClientRect();

  return {
    width: rect.width,
    height: rect.height,
    top: rect.top,
    left: rect.left,
    x: rect.x,
    y: rect.y,
    right: rect.right,
    bottom: rect.bottom,
  };
}
export function percentage(x: number, y: number) {
  return 100 / (y / x);
}
