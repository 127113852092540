import { isProduction } from "@/api/environment";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Flex, Box, VStack, IconButton } from "@chakra-ui/react";

import {
  DragHandlers,
  motion,
  Transition,
  useAnimation,
  useMotionValue,
} from "framer-motion";
import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from "react";
import { useBoundingRect } from "./useBoundingRect";

export type CarouselProps = React.PropsWithChildren<{
  gap: number;
}>;

export type SliderProps = {
  setTrackIsActive: React.Dispatch<React.SetStateAction<boolean>>;
  initSliderWidth: (width: number) => void;
  setActiveItem: React.Dispatch<React.SetStateAction<number>>;
  activeItem: number;
  constraint: number;
  itemWidth: number;
  positions: number[];
  gap: number;
  children: React.ReactNode;
};
export type TrackProps = {
  setTrackIsActive: React.Dispatch<React.SetStateAction<boolean>>;
  trackIsActive: boolean;
  setActiveItem: React.Dispatch<React.SetStateAction<number>>;
  sliderWidth: number;
  activeItem: number;
  constraint: number;
  multiplier: number;
  itemWidth: number;
  positions: number[];
  children: React.ReactNode;
};

const MotionFlex = motion(Flex);

const transitionProps: Transition = {
  type: "spring",
  mass: 3,
  damping: 120,
  stiffness: 800,
};

export function Carousel({ children, gap }: CarouselProps) {
  const [trackIsActive, setTrackIsActive] = useState(false);
  const [multiplier, setMultiplier] = useState(0.35);
  const [sliderWidth, setSliderWidth] = useState(0);
  const [activeItem, setActiveItem] = useState(0);
  const [constraint, setConstraint] = useState(0);
  const [itemWidth, setItemWidth] = useState(0);

  const initSliderWidth = useCallback(
    (width: number) => setSliderWidth(width),
    []
  );

  const positions = useMemo(
    () =>
      React.Children.map(
        children,
        (_, index) => -Math.abs((itemWidth + gap) * index)
      ) ?? ([] as number[]),

    [children, itemWidth, gap]
  );

  useEffect(() => {
    setItemWidth(sliderWidth - gap);
    setMultiplier(0.65);
    setConstraint(1);
  }, [sliderWidth, gap]);

  const sliderProps = {
    setTrackIsActive,
    initSliderWidth,
    setActiveItem,
    activeItem,
    constraint,
    itemWidth,
    positions,
    gap,
  };

  const trackProps = {
    setTrackIsActive,
    trackIsActive,
    setActiveItem,
    sliderWidth,
    activeItem,
    constraint,
    multiplier,
    itemWidth,
    positions,
    gap,
  };

  const itemProps = {
    setTrackIsActive,
    trackIsActive,
    setActiveItem,
    activeItem,
    constraint,
    itemWidth,
    positions,
    gap,
  };

  return (
    <Slider {...sliderProps}>
      <Track {...trackProps}>
        {React.Children.map(children, (child, index) => (
          <Item {...itemProps} index={index} key={index}>
            {child}
          </Item>
        ))}
      </Track>
    </Slider>
  );
}

export function Slider({
  setTrackIsActive,
  initSliderWidth,
  setActiveItem,
  activeItem,
  constraint,
  itemWidth,
  positions,
  children,
  gap,
}: SliderProps) {
  const [ref, dimensions] = useBoundingRect();
  const { width } = dimensions ?? { width: 0 };

  React.useLayoutEffect(
    () => initSliderWidth(Math.round(width)),
    [width, initSliderWidth]
  );

  const handleFocus = () => setTrackIsActive(true);

  const handleDecrementClick = () => {
    setTrackIsActive(true);
    !(activeItem === positions.length - positions.length) &&
      setActiveItem((prev) => prev - 1);
  };

  const handleIncrementClick = () => {
    setTrackIsActive(true);
    !(activeItem === positions.length - constraint) &&
      setActiveItem((prev) => prev + 1);
  };

  return (
    <>
      <Box
        ref={ref}
        h="100%"
        ml={`-${gap / 2}px`}
        px={`${gap / 2}px`}
        position="relative"
        overflow="hidden"
      >
        {children}
      </Box>

      <Flex
        w={`${itemWidth}px`}
        mt={`${gap / 2}px`}
        mx="auto"
        justifyContent="center"
        alignItems="center"
      >
        {isProduction ? null : (
          <>
            <IconButton
              aria-label="Previous"
              icon={<ChevronLeftIcon />}
              onClick={handleDecrementClick}
              onFocus={handleFocus}
              mr={`${gap / 3}px`}
              color="gray.200"
              variant="ghost"
              minW={0}
              order={-1}
            />

            <IconButton
              aria-label="Next"
              onClick={handleIncrementClick}
              onFocus={handleFocus}
              icon={<ChevronRightIcon />}
              ml={`${gap / 3}px`}
              color="gray.200"
              variant="ghost"
              zIndex={2}
              minW={0}
              order={1}
            />
          </>
        )}

        {Array.from({ length: positions.length })
          .map((_, idx) => idx)
          .map((step, index) => (
            //  circle for each step that is filled for the current step
            <Box
              key={step}
              w="2"
              h="2"
              bg={index === activeItem ? "white" : "gray.300"}
              borderRadius="full"
              m="1"
            />
          ))}
      </Flex>
    </>
  );
}

export function Track({
  setTrackIsActive,
  trackIsActive,
  setActiveItem,
  activeItem,
  constraint,
  multiplier,
  itemWidth,
  positions,
  children,
}: TrackProps) {
  const [dragStartPosition, setDragStartPosition] = useState(0);
  const controls = useAnimation();
  const x = useMotionValue(0);
  const node = useRef<HTMLDivElement>(null);

  const handleDragStart = () => setDragStartPosition(positions[activeItem]);

  const handleDragEnd =
    (type: "simple" | "advanced"): DragHandlers["onDragEnd"] =>
    (_, info) => {
      const distance = info.offset.x;
      const velocity = info.velocity.x * multiplier;
      const direction = velocity < 0 || distance < 0 ? 1 : -1;

      // Allows for a smooth transition to the closest position depending on the velocity
      if (type === "advanced") {
        const extrapolatedPosition =
          dragStartPosition +
          (direction === 1
            ? Math.min(velocity, distance)
            : Math.max(velocity, distance));

        const closestPosition = positions.reduce((prev, curr) => {
          return Math.abs(curr - extrapolatedPosition) <
            Math.abs(prev - extrapolatedPosition)
            ? curr
            : prev;
        }, 0);

        const isPositionInBounds =
          closestPosition >= 0 &&
          closestPosition <= positions[positions.length - constraint];

        if (!isPositionInBounds) {
          setActiveItem(positions.indexOf(closestPosition));
          controls.start({
            x: closestPosition,
            transition: {
              // velocity: info.velocity.x,
              ...transitionProps,
            },
          });
        } else {
          setActiveItem(positions.length - constraint);
          controls.start({
            x: positions[positions.length - constraint],
            transition: {
              // velocity: info.velocity.x,
              ...transitionProps,
            },
          });
        }
      }

      // A more simple solution, it just resolves to the next or previous position
      else {
        const closestPosition =
          direction === 1
            ? positions[activeItem + 1] ??
              positions[positions.length - constraint] // Rightmost position
            : positions[activeItem - 1] ?? positions[activeItem]; // Leftmost position

        const isPositionInBounds =
          closestPosition >= 0 &&
          closestPosition <= positions[positions.length - constraint];

        if (!isPositionInBounds) {
          setActiveItem(positions.indexOf(closestPosition));
          controls.start({
            x: closestPosition,
            transition: {
              // velocity: info.velocity.x,
              ...transitionProps,
            },
          });
        } else {
          setActiveItem(positions.length - constraint);
          controls.start({
            x: positions[positions.length - constraint],
            transition: {
              // velocity: info.velocity.x,
              ...transitionProps,
            },
          });
        }
      }
    };

  const handleResize = useCallback(
    (positions: number[]) =>
      controls.start({
        x: positions[activeItem],
        transition: transitionProps,
      }),
    [activeItem, controls]
  );

  const handleClick = useCallback(
    (event: MouseEvent) =>
      // @ts-expect-error -- This is a valid event
      node.current?.contains(event.target)
        ? setTrackIsActive(true)
        : setTrackIsActive(false),
    [setTrackIsActive]
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (trackIsActive) {
        if (activeItem < positions.length - constraint) {
          if (event.key === "ArrowRight" || event.key === "ArrowUp") {
            event.preventDefault();
            setActiveItem((prev) => prev + 1);
          }
        }
        if (activeItem > positions.length - positions.length) {
          if (event.key === "ArrowLeft" || event.key === "ArrowDown") {
            event.preventDefault();
            setActiveItem((prev) => prev - 1);
          }
        }
      }
    },
    [trackIsActive, setActiveItem, activeItem, constraint, positions.length]
  );

  useEffect(() => {
    handleResize(positions);

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("mousedown", handleClick);
    };
  }, [handleClick, handleResize, handleKeyDown, positions]);

  return (
    <>
      {itemWidth && (
        <VStack ref={node} spacing={5} alignItems="stretch" h="100%">
          <MotionFlex
            h="100%"
            dragConstraints={node}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd("simple")}
            animate={controls}
            style={{ x }}
            drag="x"
            _active={{ cursor: "grabbing" }}
            minWidth="min-content"
            flexWrap="nowrap"
            cursor="grab"
          >
            {children}
          </MotionFlex>
        </VStack>
      )}
    </>
  );
}

export type ItemProps = {
  setTrackIsActive: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveItem: React.Dispatch<React.SetStateAction<number>>;
  activeItem: number;
  constraint: number;
  itemWidth: number;
  positions: number[];
  children: React.ReactNode;
  index: number;
  gap: number;
};

export function Item({
  setTrackIsActive,
  setActiveItem,
  activeItem,
  constraint,
  itemWidth,
  positions,
  children,
  index,
  gap,
}: ItemProps) {
  const [userDidTab, setUserDidTab] = useState(false);

  const handleFocus = () => setTrackIsActive(true);

  const handleBlur = () => {
    userDidTab && index + 1 === positions.length && setTrackIsActive(false);
    setUserDidTab(false);
  };

  const handleKeyUp: React.KeyboardEventHandler = (event) => {
    event.key === "Tab" &&
      !(activeItem === positions.length - constraint) &&
      setActiveItem(index);
  };

  const handleKeyDown: React.KeyboardEventHandler = (event) =>
    event.key === "Tab" && setUserDidTab(true);

  return (
    <Flex
      onFocus={handleFocus}
      onBlur={handleBlur}
      onKeyUp={handleKeyUp}
      onKeyDown={handleKeyDown}
      w={`${itemWidth}px`}
      h="100%"
      _notLast={{ mr: `${gap}px` }}
      py="4px"
    >
      {children}
    </Flex>
  );
}
