import { QuestionIcon } from "@chakra-ui/icons";
import { FaEye, FaPersonBooth, FaUser } from "react-icons/fa";
import {
  MdGraphicEq,
  MdHome,
  MdPersonPin,
  MdPersonPinCircle,
} from "react-icons/md";

export type Route = typeof RouteMap[keyof typeof RouteMap];
export const RouteMap = {
  home: {
    path: (tab: "solo" | "mutual" = "solo") => {
      return `/home/${tab}`;
    },

    title: "Home",
    icon: <MdHome />,
  },

  help: {
    path: "/help/",
    title: "Help",
    icon: <QuestionIcon />,
  },

  privacy: {
    path: "/help/privacy",
    title: "Privacy Policy",
    icon: <FaEye />,
  },

  terms: {
    path: "/help/terms",
    title: "Terms & Conditions",
    icon: <FaPersonBooth />,
  },

  gazeCheckin: {
    path: "/gaze/checkin",
    title: "Check-in",
    icon: <MdPersonPin />,
  },

  gazeCheckout: {
    path: "/gaze/checkout",
    title: "Check-out",
    icon: <MdPersonPinCircle />,
  },

  gazeSolo: {
    path: `/gaze/solo/`,
    title: "Gaze (Solo)",
    icon: <MdPersonPinCircle />,
  },

  gazeMutual: {
    path: `/gaze/mutual/`,
    title: "Gaze (Mutual)",
    icon: <MdPersonPin />,
  },

  me: {
    path: "/me/",
    title: "Me",
    icon: <FaUser />,
  },

  meInsights: {
    path: "/me/insights/",
    title: "Insights",
    icon: <MdGraphicEq />,
  },
} as const;
