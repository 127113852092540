import { audioSelectors } from "@/services/store/slices/AudioStore";
import { useRootSelector } from "@/services/store/useStore";
import * as React from "react";
import { HandlerConfigs } from "../bridge/useBridge";

import { useAudio } from "./AudioContext";
import { Sound } from "./Sound";

type UseBackgroundAudioProps = {
  /**
   * The id or sound to play.
   * Sound must have been loaded elsewhere already.
   * Look for common sounds in {@link localSprites}
   *
   * If you pass an id, the sound must be loaded already and will play immediately.
   * If you pass a sound, the sound will be loaded and then played.
   */
  soundId: Sound["id"];

  /**
   * If the sound is bridge sound, use playmode to control the sound.
   * @see {@link useBridge} {@link HandlerNames.playAudio}
   */
  playmode?: HandlerConfigs["playAudio"]["params"]["playmode"];

  /**
   * Whether or not the background is active
   * @default true
   */
  active?: boolean;
};

export function useBackgroundAudio(props: UseBackgroundAudioProps) {
  const { soundId, active = true, playmode = "soloAmbient" } = props;

  const isMuted = useRootSelector(audioSelectors.muted);
  const { playSound, stopSound, resumeSound } = useAudio();

  const [hasPlayed, setHasPlayed] = React.useState(false);

  React.useEffect(() => {
    const shouldPlay = active && !isMuted;

    if (shouldPlay) {
      if (hasPlayed) {
        resumeSound(soundId, { loop: true, volume: 0.5, playmode });
      } else {
        playSound(soundId, { loop: true, volume: 0.5, playmode });
        setHasPlayed(true);
      }
    }

    return () => {
      stopSound(soundId);
    };
  }, [
    isMuted,
    soundId,
    active,
    playSound,
    resumeSound,
    stopSound,
    playmode,
    hasPlayed,
  ]);
}
