import { ChevronLeftIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  BoxProps,
  Flex,
  Grid,
  GridProps,
  Heading,
  HeadingProps,
  IconButton,
  Stack,
  StackProps,
  Text,
  TextProps,
} from "@chakra-ui/react";
import { motion, MotionProps } from "framer-motion";
import * as React from "react";

import { QuestionnaireStep } from "./Questionnaire";

export type QuestionnaireContainerProps = {
  label: QuestionnaireStep["label"];
  description?: QuestionnaireStep["description"];
  children?: React.ReactNode;
  footer?: React.ReactNode;

  childStyle?: StackProps;
  containerStyle?: GridProps;
  labelStyle?: HeadingProps & MotionProps;

  pagination?: { total: number; current: number };

  backButton?: {
    show: boolean;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
  };

  cancelButton?: {
    show: boolean;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
  };
};

const MotionHeading = motion<HeadingProps>(Heading);
const MotionText = motion<TextProps>(Text);
const MotionBox = motion<BoxProps>(Box);

const animations = {
  heading: {
    centered: { opacity: 1, y: 250 },
    slideInTop: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 1,
        duration: 0.5,
        ease: "easeInOut",
      },
    },
  },

  description: {
    hidden: { opacity: 0 },
    slideInTop: {
      opacity: 1,
      transition: { delay: 1.5, duration: 0.5, ease: "easeInOut" },
    },
  },

  option: (index: number) =>
    ({
      hidden: { opacity: 0, y: 100, pointerEvents: "none" },
      slideInTop: {
        pointerEvents: "auto",
        opacity: 1,
        y: 0,
        transition: {
          delay: 1.5 + index * 0.1,
          duration: 0.5,
          ease: "easeInOut",
        },
      },
    } as const),
};
export function QuestionnaireContainer(props: QuestionnaireContainerProps) {
  const {
    label,
    description,
    children,
    footer,

    childStyle,
    containerStyle,
    labelStyle,

    backButton,
    cancelButton,
    pagination,
  } = props;

  return (
    <Grid
      px="5%"
      py="12"
      height="100%"
      gridTemplateAreas={`
        'back . cancel'
        'pagination pagination pagination'
        'header header header'
        'children children children'
        'footer footer footer'
      `}
      gridTemplateRows="auto auto auto 1fr min-content"
      gridTemplateColumns="auto 1fr auto"
      alignItems="center"
      justifyContent="center"
      {...containerStyle}
    >
      {backButton?.show ? (
        <IconButton
          aria-label="Back"
          onClick={backButton.onClick}
          icon={<ChevronLeftIcon />}
          fontSize="2xl"
          variant="ghost"
          borderRadius="full"
          gridArea="back"
        />
      ) : null}

      {cancelButton?.show ? (
        <IconButton
          aria-label="Cancel"
          onClick={cancelButton.onClick}
          icon={<CloseIcon />}
          borderRadius="full"
          variant="ghost"
          gridArea="cancel"
        />
      ) : null}

      {pagination ? (
        <Flex
          width="100%"
          maxWidth="xl"
          gridArea="pagination"
          alignItems="center"
          justifyContent="center"
          mx="auto"
          my="4"
        >
          {Array.from({ length: pagination.total })
            .map((_, idx) => idx)
            .map((step, index) => (
              //  circle for each step that is filled for the current step
              <Box
                flex="0.5 1 auto"
                key={step}
                w="2"
                h="2"
                bg={index === pagination.current ? "brandPink" : "gray.300"}
                borderRadius="full"
                m="1"
              />
            ))}
        </Flex>
      ) : null}

      {(typeof label === "string" && label !== "") ||
      (typeof description === "string" && description !== "") ? (
        <Box gridArea="header" alignSelf="center" textAlign="center" my="8">
          <MotionHeading
            key={label}
            size="sm"
            initial="centered"
            animate="slideInTop"
            variants={animations.heading}
            {...labelStyle}
          >
            {label}
          </MotionHeading>

          {description ? (
            <MotionText
              key={description}
              fontSize="xs"
              initial="hidden"
              animate="slideInTop"
              variants={animations.description}
            >
              {description}
            </MotionText>
          ) : null}
        </Box>
      ) : null}

      <Stack
        {...childStyle}
        gridArea="children"
        alignSelf="end" // aligns the entire box to the bottom of the grid
        align="center" // aligns the entire box to the center of the grid
        height="100%" // takes up remaining space in the grid
        overflowY="auto" // scroll options if the content is too long
        rounded="lg"
      >
        {React.Children.map(children, (child, index) => (
          <MotionBox
            key={`${label}-${index}`}
            width="100%"
            maxWidth="xl"
            initial="hidden"
            animate="slideInTop"
            variants={animations.option(index)}
          >
            {child}
          </MotionBox>
        ))}
      </Stack>

      {footer ? (
        <Box gridArea="footer" alignSelf="end" justifySelf="center" mt="4">
          {footer}
        </Box>
      ) : null}
    </Grid>
  );
}
