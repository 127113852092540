import { Box, BoxProps } from "@chakra-ui/react";
import * as React from "react";

/** Component to render a block of code */
export function CodeBlock({ children, ...rest }: BoxProps) {
  return (
    <Box
      as="pre"
      bg="blackAlpha.600"
      borderRadius="md"
      p="2"
      fontFamily="mono"
      overflow="auto"
      {...rest}
    >
      {children ?? "null or undefined"}
    </Box>
  );
}
