import {
  BoxProps,
  Button,
  ButtonGroup,
  ButtonProps,
  Heading,
  Input,
  InputProps,
  Stack,
} from "@chakra-ui/react";
import * as React from "react";

import { Carousel, CarouselProps } from "@/components/carousel/Carousel";
import { CodeBlock } from "@/components/CodeBlock";
import { useControllerActions } from "@/features/controller/useController";
import {
  QuestionnaireContainer,
  QuestionnaireContainerProps,
} from "./QuestionnaireContainer";

export type QuestionnaireValues = {
  [stepId: string]: Array<string>;
};

export type QuestionnaireStep = {
  id: string;
  label: string;
  description?: string;
  validate?: (state: QuestionnaireValues) => string | null; // returns error message or true, if valid
} & (
  | {
      type: "select"; // Use to select one (or multiple) of the options
      options: Array<string>; // The options to select from
      multiple?: boolean; // Allow multiple selections
    }
  | {
      type: "conditional"; // Use to show/skip a step, based on the nextStepId attached to the value
      options: Array<{
        value: string; // Value to match
        nextStepId: string; // The next step to show
      }>;
    }
  | {
      type: "range"; // Use to show a list of ranges (e.g. 1-10)
      options: Array<{
        label: string; // Label to show
        range: Array<string>; // Range of values to choose from
      }>;
    }
  | {
      type: "text"; // Use to show a text input
      placeholder?: string; // Placeholder text
      buttonText?: string; // Text to show on the button
    }
  | {
      type: "next"; // Use to show the next step
      buttonText?: string; // Text to show on the button
    }
  | {
      type: "async-action"; // Use to submit an async action
      buttonText?: string; // Text to show on the button
      action: (state: QuestionnaireValues) => void | Promise<void>; // The action to perform
    }
  | {
      type: "custom"; // Use to show a custom component
      render: (
        state: QuestionnaireValues,
        handleNext: (nextStepIndex?: number) => Promise<void>
      ) => React.ReactElement;
    }
  | {
      type: "carousel"; // Use to show a carousel
      buttonText?: string; // Text to show on the button

      carouselProps?: Omit<CarouselProps, "children">;
      options: Array<any>;
      renderOption: (
        option: any,
        state: QuestionnaireValues,
        actions: {
          handleNext: (nextStepIndex?: number) => Promise<void>;
          setValues: React.Dispatch<React.SetStateAction<QuestionnaireValues>>;
        }
      ) => React.ReactElement;
    }
);

export type QuestionnaireProps = {
  steps: Array<QuestionnaireStep>; // The steps to show
  nextProps?: Partial<Omit<ButtonProps, "children">>; // Props to pass to the next button
  containerProps?: Partial<Omit<QuestionnaireContainerProps, "children">>; // Props to pass to the container

  onSubmit: (values: QuestionnaireValues) => void; // Called when the final step is completed
  onCancel?: () => void; // Called when the user cancels the questionnaire
};
export function Questionnaire({
  steps,
  nextProps,
  containerProps,

  onSubmit,
  onCancel,
}: QuestionnaireProps) {
  const { emit } = useControllerActions();

  const [values, setValues] = React.useState<QuestionnaireValues>({});
  const [currentStepIndex, setCurrentStepIndex] = React.useState<number>(
    () => 0
  );
  const history = React.useRef<number[]>([currentStepIndex]);

  const currentStep = steps[currentStepIndex];
  const isLastStep = currentStepIndex === steps.length - 1;

  const handleNext = async (stepIndex?: number) => {
    const error = currentStep.validate?.(values);

    if (error) {
      await emit("error", { data: error });
      return;
    }

    await new Promise((resolve) => setTimeout(resolve, 500));

    if (stepIndex !== undefined) {
      history.current.push(stepIndex);
      setCurrentStepIndex(stepIndex);
    } else if (currentStepIndex < steps.length - 1) {
      history.current.push(currentStepIndex + 1);
      setCurrentStepIndex(currentStepIndex + 1);
    } else if (isLastStep) {
      onSubmit(values);
    } else {
      await emit(
        "error",
        { data: "Invalid step " + currentStepIndex + ": " + currentStep.id },
        { toast: { title: "Questionnaire" } }
      );
    }
  };

  // a click handler for handleNext
  const makeNextHandler =
    (...args: Parameters<typeof handleNext>) =>
    () =>
      handleNext(...args);

  const handleBack = React.useCallback(() => {
    if (currentStepIndex > 0) {
      history.current.pop();
      setCurrentStepIndex(history.current[history.current.length - 1]);
    }
  }, [currentStepIndex]);

  const makeSelectChangeHandler = (id: string, value: string) => () => {
    setValues((prev) => {
      const fieldExists = Array.isArray(prev[id]);
      const valuesExistsInField = fieldExists && prev[id].includes(value);

      if (valuesExistsInField) {
        return { ...prev, [id]: prev[id].filter((v) => v !== value) }; // remove the value
      }

      if (fieldExists) {
        return { ...prev, [id]: [...prev[id], value] }; // update the field with value
      }

      return { ...prev, [id]: [value] }; // create the field with value
    });

    const shouldGoToNextStep =
      "multiple" in currentStep && currentStep.multiple !== true;

    if (shouldGoToNextStep) {
      handleNext();
    }
  };

  const makeConditionalChangeHandler =
    (value: string, nextStepId: QuestionnaireStep["id"]) => () => {
      setValues((prev) => ({ ...prev, [currentStep.id]: [value] }));

      const nextStepIndex = steps.findIndex((step) => step.id === nextStepId);

      return handleNext(nextStepIndex);
    };

  const makeRangeChangeHandler = (label: string, value: string) => () => {
    const { isSelected, fieldId } = isValueInRange(
      currentStep.id,
      label,
      value
    );

    if (isSelected) {
      // remove the value
      setValues((prev) => ({
        ...prev,
        [fieldId]: prev[fieldId].filter((v) => v !== value),
      }));
    } else {
      setValues((prev) => ({ ...prev, [fieldId]: [value] })); // update/create the field with value
    }
  };

  const makeAsyncActionHandlers = async () => {
    if ("action" in currentStep) {
      try {
        await currentStep.action(values);

        return handleNext();
      } catch (_error) {
        const error =
          _error instanceof Error ? _error.message : JSON.stringify(_error);

        return emit("error", { data: error });
      }
    }
  };

  const makeTextChangeHandler =
    (id: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setValues((prev) => ({ ...prev, [id]: [value] }));
    };

  const containerBoxProps: Omit<
    QuestionnaireContainerProps,
    "children" | "next"
  > = {
    label: currentStep.label,
    description: currentStep.description,
    pagination: { total: steps.length, current: currentStepIndex },

    backButton: { show: currentStepIndex > 0, onClick: handleBack },
    cancelButton: onCancel ? { show: true, onClick: onCancel } : undefined,
    ...containerProps,

    childStyle: { overflowX: "hidden", ...containerProps?.childStyle },
  };

  const isValueInRange = (
    stepId: string = currentStep.id,
    label: string,
    value: string
  ) => {
    const safeLabel = label
      .replace(/\s/g, "_") // replace spaces with underscores
      .replace(/[^a-zA-Z0-9_]/g, "") // remove all non-alphanumeric characters
      .toLowerCase(); // convert to lowercase

    const fieldId = stepId + "__" + safeLabel;

    const fieldExists = Array.isArray(values[fieldId]);

    const valuesExistsInField = fieldExists && values[fieldId].includes(value);

    return { isSelected: valuesExistsInField, fieldId };
  };

  const isValueSelected = (stepId: string = currentStep.id, value: string) => {
    const selectedValues = values[stepId];
    const isSelected = selectedValues && selectedValues.includes(value);

    return isSelected;
  };

  const makeBaseOptionProps = (
    isSelected: boolean
  ): Omit<BoxProps & ButtonProps & InputProps, "onChange" | "onClick"> => {
    const selectedProps = {
      paddingLeft: "4",
      transition: "all 0.3s ease-in-out",
      bg: "gray.600",
    } as const;

    const defaultProps = {
      display: "block",
      mx: "auto",
      fontSize: "xl",
      fontWeight: "normal",
      textTransform: "capitalize",
      justifyContent: "flex-start",
      textAlign: "left",
      py: "4",
      px: "8",
      width: "100%",
      height: "auto",
      boxShadow: "lg",
      rounded: "lg",

      _hover: selectedProps,
      _focus: selectedProps,
    } as const;

    if (isSelected) {
      return { ...defaultProps, ...selectedProps };
    }

    return defaultProps;
  };

  const makeNextButtonProps = (): ButtonProps => ({
    ...makeBaseOptionProps(false),

    variant: "primary",
    width: "auto",
    rounded: "full",

    _hover: undefined,
    _focus: undefined,

    onClick: makeNextHandler(),
    ...nextProps,
  });

  switch (currentStep.type) {
    case "select": {
      return (
        <QuestionnaireContainer
          {...containerBoxProps}
          footer={
            currentStep.multiple && (
              <Button {...makeNextButtonProps()}>Next</Button>
            )
          }
        >
          {currentStep.options.map((option) => (
            <Button
              key={option}
              onClick={makeSelectChangeHandler(currentStep.id, option)}
              {...makeBaseOptionProps(isValueSelected(currentStep.id, option))}
            >
              {option}
            </Button>
          ))}
        </QuestionnaireContainer>
      );
    }

    case "conditional": {
      return (
        <QuestionnaireContainer {...containerBoxProps}>
          {currentStep.options.map((option) => (
            <Button
              key={option.value}
              onClick={makeConditionalChangeHandler(
                option.value,
                option.nextStepId
              )}
              rounded="full"
              justifyContent="center"
              textAlign="center"
              {...makeBaseOptionProps(
                isValueSelected(currentStep.id, option.value)
              )}
            >
              {option.value}
            </Button>
          ))}
        </QuestionnaireContainer>
      );
    }

    case "range": {
      return (
        <QuestionnaireContainer
          {...containerBoxProps}
          childStyle={{ spacing: "6" }}
          footer={
            <Button
              {...makeNextButtonProps()}
              onClick={makeNextHandler(currentStepIndex + 1)}
            >
              Next
            </Button>
          }
        >
          {currentStep.options.map((option) => (
            <Stack key={option.label} spacing="2">
              <Heading size="md">{option.label}</Heading>

              <ButtonGroup>
                {option.range.map((value) => (
                  <Button
                    key={value}
                    onClick={makeRangeChangeHandler(option.label, value)}
                    {...makeBaseOptionProps(
                      isValueInRange(currentStep.id, option.label, value)
                        .isSelected
                    )}
                    flex="1"
                  >
                    {value}
                  </Button>
                ))}
              </ButtonGroup>
            </Stack>
          ))}
        </QuestionnaireContainer>
      );
    }

    case "text": {
      return (
        <QuestionnaireContainer
          {...containerBoxProps}
          footer={
            <Button {...makeNextButtonProps()}>
              {currentStep.buttonText || "Next"}
            </Button>
          }
        >
          <Input
            id={currentStep.id}
            placeholder={currentStep.placeholder}
            onChange={makeTextChangeHandler(currentStep.id)}
            {...makeBaseOptionProps(false)}
          />
        </QuestionnaireContainer>
      );
    }

    case "async-action": {
      return (
        <QuestionnaireContainer
          {...containerBoxProps}
          footer={
            <Button
              {...makeNextButtonProps()}
              onClick={makeAsyncActionHandlers}
            >
              {currentStep.buttonText || "Next"}
            </Button>
          }
        />
      );
    }

    case "next": {
      return (
        <QuestionnaireContainer
          {...containerBoxProps}
          labelStyle={{ size: "xl", maxWidth: "22ch", mx: "auto" }}
          childStyle={{
            ...containerBoxProps.childStyle,
            justifyContent: "end",
          }}
          footer={
            <Button {...makeNextButtonProps()}>
              {currentStep.buttonText || "Next"}
            </Button>
          }
        />
      );
    }

    case "custom": {
      return (
        <QuestionnaireContainer {...containerBoxProps}>
          {currentStep.render(values, handleNext)}
        </QuestionnaireContainer>
      );
    }

    case "carousel": {
      const { carouselProps = { gap: 0 }, options, renderOption } = currentStep;

      return (
        <QuestionnaireContainer
          {...containerBoxProps}
          childStyle={{
            justifyContent: "center",
            ...containerBoxProps.childStyle,
          }}
          footer={
            <Button {...makeNextButtonProps()}>
              {currentStep.buttonText || "Next"}
            </Button>
          }
        >
          <Carousel {...carouselProps}>
            {options.map((option) =>
              renderOption(option, values, { handleNext, setValues })
            )}
          </Carousel>
        </QuestionnaireContainer>
      );
    }

    default: {
      return <CodeBlock>{JSON.stringify(currentStep, null, 2)}</CodeBlock>;
    }
  }
}
